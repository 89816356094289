.list-unstyled {
  list-style-type: none;
}

.list-unstyled li {
  margin-bottom: 5px;
}

.footer-index {
  background: var(--theme-primary);
  color: white;
}

.footer-index a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.815);
  font-size: 14px;
  transition-duration: 0.2s;
  list-style-type: none;
  letter-spacing: 0.015em;
}

.footer-index a:hover {
  color: #0d6efd !important;
}

.copy {
  font-size: 15px;
  padding: 10px;
  border-top: 1px solid #ffffff;
}

.footer-middle {
  padding-top: 2em;
  color: white;
}

@media only screen and (max-width: 600px) {
  .list-unstyled {
    text-align: center;
  }
}

.footer-head-text-style {
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.015em;
  -moz-font-feature-settings: "calt=0,liga=0";
  font-feature-settings: "liga" 0;
  line-height: 1.5;
  font-size: 18px;
}

.footer-hr {
  color: rgba(255, 255, 255, 0.842);
  width: 80%;
  height: 3px !important;
}

.footer-p-tag {
  color: rgba(255, 255, 255, 0.815);
  font-size: 14px;
  transition-duration: 0.2s;
  list-style-type: none;
  letter-spacing: 0.015em;
}
