:root {
  --theme-primary: #182d45;
  --theme-primary-light: #182d4515;
  --theme-secondary: #fab300;
  --theme-secondary-light: #fab30063;
  --theme-active: #18b69e;
  --theme-link: #00b1ff;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "RobotoRegular";
}

@font-face {
  font-family: "RobotoRegular";
  src: url("./fonts/RobotoCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  src: url("./fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: url("./fonts/RobotoCondensed-Light.ttf") format("truetype");
}

.head-text-style {
  color: #404343;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.015em;
  -moz-font-feature-settings: "calt=0,liga=0";
  font-feature-settings: "liga" 0;
  line-height: 1.5;
}

.gray-text-style {
  color: #4c5050;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.015em;
  -moz-font-feature-settings: "calt=0,liga=0";
  font-feature-settings: "liga" 0;
}

.center {
  text-align: center;
}

.hide-div {
  display: none;
}

/* header text types */
.header-text-1 {
  line-height: 1.231;

  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.015em;
  -moz-font-feature-settings: "calt=0,liga=0";
  font-feature-settings: "liga" 0;
}

.header-text-2 {
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.015em;
  -moz-font-feature-settings: "calt=0,liga=0";
  font-feature-settings: "liga" 0;
  color: #404343;
  display: block;
  line-height: 1.231;
  margin: 0;
  padding: 0 0 0.35em;
}

.header-text-3 {
  font-weight: 100;
  font-style: normal;
  letter-spacing: 0.015em;
  -moz-font-feature-settings: "calt=0,liga=0";
  font-feature-settings: "liga" 0;
  color: #404343;
  display: block;
  line-height: 1.231;
  margin: 0;
  padding: 0 0 0.35em;
}

.sub-text-1 {
  font-weight: 400;
  font-style: normal;
  -moz-font-feature-settings: "calt=0,liga=0";
  font-feature-settings: "liga" 0;
  font-size: 20px;
}

.global-hr-tag {
  background: var(--theme-secondary);
  content: "";
  display: block;
  height: 4px !important;
  margin: 10px 0 30px 0;
  width: 100px;
  opacity: 1;
}

.a-tag-style {
  text-decoration: none;
  font-size: 18px;
  font-style: italic;
}
