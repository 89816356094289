/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .parallax-img-home {
    background-image: url("home-back.jpg");
    /* min-height: 110vh; */
  }

  .home-hero .hero-text h2 {
    margin-bottom: 20px;
    font-size: 17px;
  }

  .home-hero .hero-text h3 {
    font-size: 25px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .parallax-img-home {
    background-image: url("home-back.jpg");
    /* min-height: 110vh; */
  }

  .home-hero .hero-text h2 {
    margin-bottom: 20px;
    font-size: 2rem;
  }

  .home-hero .hero-text h3 {
    font-size: 2.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .parallax-img-home {
    background-image: url("home-back.jpg");
    /* min-height: 110vh; */
  }

  .home-hero .hero-text h2 {
    margin-bottom: 20px;
    font-size: 2rem;
  }

  .home-hero .hero-text h3 {
    font-size: 2.5rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .parallax-img-home {
    background-image: url("home-back.jpg");
    /* min-height: 90vh; */
  }

  .home-hero .hero-text h2 {
    margin-bottom: 20px;
    font-size: 2rem;
  }

  .home-hero .hero-text h3 {
    font-size: 2.5rem;
  }
}
